// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "../stylesheets/application"

import "bootstrap/dist/js/bootstrap.bundle.min"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', () => {
  if(document.querySelector('[data-navigation]') && !document.querySelector('[data-navigation]').classList.contains('attached')) {
    const navOpen = document.querySelector('[data-navigation-open]');
    const navClose = document.querySelector('[data-navigation-close]');
    const navBar = document.querySelector('[data-navigation]');

    // navBar.classList.add('attached');

    navOpen.addEventListener('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      navBar.classList.contains('opened') ? navBar.classList.remove('opened') : navBar.classList.add('opened')
    })

    navClose.addEventListener('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      navBar.classList.contains('opened') ? navBar.classList.remove('opened') : navBar.classList.add('opened')
    })

  }
})


// our simple toast
window.PerfilToast = function(message, type = 'default', autoClose = true) {
  const containerId = "perfilToastContainer";
  const autoCloseTimeout = 3500;
  // document.
  // first create our container
  var containerEl;
  if(document.querySelector(`#${containerId}`) === null) {
    containerEl = document.createElement('div');
    containerEl.setAttribute('id', containerId);
    containerEl.classList.add('perfil-toast-container');
    document.body.appendChild(containerEl);
  } else {
    containerEl = document.querySelector(`#${containerId}`);
  }

  // create notification and add to container
  const notificationEl = document.createElement('div');
  // notificationEl.setAttribute('');
  notificationEl.classList.add('perfil-toast-notification');
  notificationEl.classList.add(`toast-${type}`);
  notificationEl.innerHTML = message;
  containerEl.appendChild(notificationEl);
  if(autoClose) {
    setTimeout(() => {
      notificationEl.remove();
    }, autoCloseTimeout)
  }
}

window.PerfilSlugValidation = function(input) {
  const _regex = /^[a-zA-Z0-9_]{3,}[a-zA-Z]+[0-9]*$/
  const _regexReplace = /[^a-zA-Z0-9_]/g

  return input.replace(_regexReplace, '').toLowerCase();
}

document.addEventListener("turbolinks:load", () => {
  if(document.querySelector('#site_slug') && (!document.querySelector('#site_slug').dataset.attached)) {
    let _siteSlugInput = document.querySelector('#site_slug');
    _siteSlugInput.dataset.attached = true;
    let _siteSlugCheck = function(e) {
      _siteSlugInput.value = window.PerfilSlugValidation(_siteSlugInput.value);
    }
    _siteSlugInput.addEventListener('keyup', _siteSlugCheck);
    _siteSlugInput.addEventListener('change', _siteSlugCheck);
    _siteSlugInput.addEventListener('keydown', _siteSlugCheck);
  }

  if(document.querySelector('#user_slug') && (!document.querySelector('#user_slug').dataset.attached)) {
    let _userSlugInput = document.querySelector('#user_slug');
    _userSlugInput.dataset.attached = true;
    let _userSlugCheck = function(e) {
      _userSlugInput.value = window.PerfilSlugValidation(_userSlugInput.value);
    }
    _userSlugInput.addEventListener('keyup', _userSlugCheck);
    _userSlugInput.addEventListener('change', _userSlugCheck);
    _userSlugInput.addEventListener('keydown', _userSlugCheck);
  }
})






// <script>

// let _slugInput = document.getElementById('user_slug');
// // let _slugHintEl = document.querySelector('.site_slug small');
// let _regex = /^[a-zA-Z0-9_]{3,}[a-zA-Z]+[0-9]*$/
// let _regexReplace = /[^a-zA-Z0-9_]/g
// // _slugHintEl.classList.add('d-none')

// let checkSlug = function(e) {
//   // console.log(_slugInput.value)
//   // if(_slugInput.value.length >= 4 && !_regex.test(_slugInput.value)) {
//   //   // preencheo o suficiente e ainda está invalido
//   //   // exibe a dica
//   //   if(_slugHintEl.classList.contains('d-none'))
//   //     _slugHintEl.classList.remove('d-none')
//   // } else if(_slugInput.value.length >= 3 && _regex.test(_slugInput.value)) {
//   //   if(!_slugHintEl.classList.contains('d-none'))
//   //     _slugHintEl.classList.add('d-none')
//   // } else {
//   //   if(!_slugHintEl.classList.contains('d-none'))
//   //     _slugHintEl.classList.add('d-none')
//   // }
//   // subsitui os caracteres invalidos
//   _slugInput.value = _slugInput.value.replace(_regexReplace, '').toLowerCase();

//   return;
// }

// _slugInput.addEventListener('keyup', checkSlug);
// _slugInput.addEventListener('change', checkSlug);
// _slugInput.addEventListener('keydown', checkSlug);



// </script>
